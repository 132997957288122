/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { setGlobal, useGlobal } from 'reactn';
import moment from 'moment';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import ConfigProvider from 'antd/lib/config-provider';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import enUS from 'antd/lib/locale-provider/en_US';
import AuthLayout from './layouts/AuthLayout';
import MainLayout from './layouts/MainLayout';
import ReportsLayout from './layouts/ReportsLayout';
import client from './api/graphql/client';
import history from './api/redux/history';
import store from './api/redux/store';
import ErrorBoundary from './modules/error-handling/components/ErrorBoundary';
import NetworkIndicator from './modules/network-indicator/components/NetworkIndicator';
import Cookies from 'js-cookie';

import { QueryClient, QueryClientProvider } from 'react-query';
import { trpc } from './utils/trpc';

import './api/sentry';
import '../node_modules/antd/dist/antd.css';
import './globalStyles';

import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css';

import { datadogRum } from '@datadog/browser-rum';
import { activityTimer } from './utils/activityTimer';

if (!window.location.host.includes('localhost')) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DDOG_APP_ID,
    clientToken: process.env.REACT_APP_DDOG_CLIENT_TOKEN,
    site: 'us5.datadoghq.com',
    // service:'portal-(stg)',
    env: process.env.REACT_APP_DDOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: process.env.NODE_ENV,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();
}

const sessionCookie = Cookies.get('next-auth.session-token');

// send to sign in if not logged in
if (!sessionCookie && !window.location.href.includes('/auth/')) {
  // check if accessing reports/timesheets url. This is used by PDF generation and should not redirect to sign in
  if (!window.location.href.includes('/reports/timesheet')) {
    window.location.href = `${process?.env.REACT_APP_GRAPHQL_BASE_URL}/api/auth/signin`;
  }
}

// setTimeout(() => {
//   console.log(sessionCookie.test.ste);
// }, 5000);

setGlobal({
  networkStatus: {},
  user: null,
  // jwt: localStorage.getItem('jwt'),
  jwt: sessionCookie,
  loading: false,
  endDate: moment().endOf('isoWeek').format('YYYYMMDD'),
  range: {
    startDate: moment().subtract(2, 'month').startOf('month'),
    endDate:
      moment().endOf('month').diff(moment(), 'days') < 7
        ? moment().endOf('month').add(1, 'month')
        : moment().endOf('month'),
  },
  config: {
    'company-short-name': '',
    'favicon-url': '',
    'logo-auth-url': '',
    'logo-topbar-url': '',
  },
});

// const configuration = {
//   auth: {
//     clientId: 'f84abb99-b54f-48e7-9f9f-cc99a033e618',
//     authority: 'https://login.microsoftonline.com/becab91e-77c7-4f1f-9351-8df3aefbcf97',
//     redirectUri: `${process?.env.REACT_APP_BASE_URL}/_oauth/azureAd`,
//     postLogoutRedirectUri: `${process?.env.REACT_APP_BASE_URL}/auth/signin`,
//     navigateToLoginRequestUrl: false,
//   },
// };

// const pca = new PublicClientApplication(configuration);

const SiteConfigProvider = ({ children }) => {
  const [, setConfig] = useGlobal('config');
  const { loading, data } = useQuery(
    gql`
      query {
        configurations {
          key
          value
        }
      }
    `,
  );

  useEffect(() => {
    if (!loading && data?.configurations) {
      const _config = data.configurations.reduce((acc, cur) => {
        if (!acc[cur.key]) {
          return { ...acc, [cur.key]: cur.value };
        }
        return acc;
      }, {});
      setConfig(_config);
    }
  }, [loading, data, setConfig]);

  // if (loading || !config) {
  //   return <TextLoader text="Loading environment" />;
  // }

  return children;
};

const App = () => {
  useEffect(() => {
    activityTimer(10 * 60 * 1000);
  }, []);
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      url: `${process?.env.REACT_APP_GRAPHQL_BASE_URL}/api/trpc`,
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        });
      },

      // optional
      headers() {
        return {
          // authorization: getAuthCookie(),
        };
      },
    }),
  );

  return (
    <ErrorBoundary>
      {/* <MsalProvider instance={pca}> */}
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ApolloProvider client={client}>
            <Provider store={store}>
              <ConnectedRouter history={history}>
                <ConfigProvider locale={enUS}>
                  <NetworkIndicator />
                  <SiteConfigProvider>
                    <Switch>
                      <Route exact path="/" render={() => <Redirect to="/auth/signin" />} />
                      <Route path="/user" component={MainLayout} />
                      <Route path="/project-management" component={MainLayout} />
                      <Route path="/vaccine-tracker" component={MainLayout} />
                      <Route path="/finance" component={MainLayout} />
                      <Route path="/admin" component={MainLayout} />
                      <Route path="/performance" component={MainLayout} />
                      <Route path="/human-resources" component={MainLayout} />
                      <Route path="/reports" component={ReportsLayout} />
                      <Route path="/auth" component={AuthLayout} />
                      <Route path="/_oauth" component={AuthLayout} />
                      <Route render={() => <div>404</div>} />
                    </Switch>
                  </SiteConfigProvider>
                </ConfigProvider>
              </ConnectedRouter>
            </Provider>
          </ApolloProvider>
        </QueryClientProvider>
      </trpc.Provider>
      {/* </MsalProvider> */}
    </ErrorBoundary>
  );
};

export default App;
