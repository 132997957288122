import React, { useState } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Query } from 'react-apollo';
import { useGlobal } from 'reactn';
import { Alert, InputNumber, Tooltip } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Table from '../../common/components/Table';
import Card from '../../common/components/Card';
import Tag from '../../common/components/Tag';
import Modal from '../../common/components/Modal';
import Button from '../../common/components/Button';
import ProjectDetailsDeliverableActions from './ProjectDetailsDeliverableActions';
import AddDeliverableForm from '../../common/components/UpdateDeliverableForm/AddDeliverableForm';
import { GET_DELIVERABLES_BY_PROJECT_ID } from '../queries';
import ProjectDetailsDeliverablesBudgetChart from './ProjectDetailsDeliverablesBudgetChart';
import ProjectDetailsMilestoneBudgetChart from './ProjectDetailsMilestoneBudgetChart';

// const { Option } = Select;

const ProjectDetailsDeliverableList = ({ projectId, isPaginated = true, refetchQueries }) => {
  const [lockEditing] = useGlobal('lockEditing');
  const [config] = useGlobal('config');
  const netSuiteUrl = config['netsuite-url'];
  const [showModal, setShowModal] = useState(false);
  const [blendedRate, setBlendedRate] = useState(config['blended-rate']);
  const [chartType] = useState('milestone'); // milestone or deliverable
  // const hasFinanceRole = user.groups.includes('finance');

  const createHeaderComponent = deliverables => {
    return (
      <div className="p-4">
        {chartType === 'deliverable' && (
          <ProjectDetailsDeliverablesBudgetChart
            deliverables={deliverables}
            header={renderSelect()}
          />
        )}
        {chartType === 'milestone' && (
          <ProjectDetailsMilestoneBudgetChart deliverables={deliverables} header={renderSelect()} />
        )}
      </div>
    );
  };

  const renderSelect = () => {
    return null;
    // TODO: The selector is not working as expected.
    // return (
    //   <Select value={chartType} onChange={value => setChartType(value)}>
    //     <Option value="milestone">Milestones</Option>
    //     <Option value="deliverable">Deliverables</Option>
    //   </Select>
    // );
  };

  const createColumns = ({ deliverables, milestoneNames, refetchQueries }) => [
    {
      title: 'Category',
      dataIndex: 'milestoneName',
      key: 'milestoneName',
      width: 300,
      filters: deliverables.reduce((acc, cur) => {
        if (acc.find(m => m.text === cur.milestoneName)) return acc;
        return [
          ...acc,
          {
            text: cur.milestoneName,
            value: cur.milestoneName,
          },
        ];
      }, []),
      onFilter: (value, record) => record.milestoneName.indexOf(value) === 0,
      render: (value, record) => {
        if (record.children) {
          return (
            <span className="inline-flex items-center">
              <span>{value}</span>
              <Link
                to={`/project-management/all-projects/${record.projectId}/financials?category=${value}`}
              >
                <Tooltip title="See corresponding financial data">
                  <Button icon="file-search" className="ml-2" />
                </Tooltip>
              </Link>
            </span>
          );
        }
        return value;
      },
    },
    {
      title: 'Task',
      key: 'deliverableName',
      width: 200,
      render: data => {
        if (data?.integration?.sourceId) {
          return (
            <a
              href={`${netSuiteUrl}/app/accounting/project/projecttask.nl?id=${data.integration.sourceId}`}
              target="_blank"
              rel="noreferrer"
            >
              {data.deliverableName}
            </a>
          );
        } else {
          return <div className="flex justify-end">{data.deliverableName}</div>;
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: data => {
        if (data === 'Not Started') {
          return <Tag color="gray">{data}</Tag>;
        }
        if (data === 'In Progress') {
          return <Tag color="blue">{data}</Tag>;
        }
        if (data === 'Completed') {
          return <Tag color="green">{data}</Tag>;
        }
      },
    },
    {
      title: 'Budget Status',
      dataIndex: 'budgetStatus',
      key: 'budgetStatus',
      render: data => {
        if (data) {
          return <Tag color={data === 'Overbudget' ? 'red' : 'green'}>{data}</Tag>;
        }
      },
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      render: data => {
        if (data === true) {
          return <Tag color="red">Yes</Tag>;
        }
      },
    },
    {
      title: 'Cost',
      key: 'actuals',
      width: 150,
      render: data => {
        return numeral(data.totalCost).format('$0,0');
      },
    },
    {
      title: 'Budget (% of total)',
      key: 'budget',
      width: 200,
      render: data => {
        if (!data.budget && !data.budgetPercent) {
          return <div className="text-gray-400">Not set</div>;
        }
        const showPercentage = isFinite(data.calculatedBudgetPercent);
        return (
          <div>
            <span>{numeral(data.budget).format('$0,0.[00]')}</span>
            {showPercentage && (
              <Tooltip title={data.budgetPercent > 0 ? '% set explicitly' : '% calculated'}>
                <span
                  className={classNames({
                    'text-gray-500': !data.budgetPercent,
                    'font-semibold': data.budgetPercent > 0,
                  })}
                >{` (${numeral((data.budgetPercent || data.calculatedBudgetPercent) / 100).format(
                  '0,0.[00]%',
                )})`}</span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Aprv. Hours / Est. Hours',
      width: 250,
      render: data => {
        return (
          <div>
            <span>{numeral(data.totalApprovedHours).format('0,0.[0]')} hours</span>
            {data.budget > 0 && blendedRate > 0 && (
              <span>
                <Tooltip
                  title={`Estimated number of hours budgeted based on a blended rate of $${blendedRate}`}
                >
                  {` / ${numeral(data.budget / blendedRate).format('0,0.[0]')} hours`}
                </Tooltip>
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Rate',
      // dataIndex: 'rate',
      key: 'rate',
      width: 100,
      render: data => {
        if (!data.deliverableName) {
          return null;
        }
        if (data.rate) {
          return numeral(data.rate).format('$0,0.0');
        }
        return <div className="text-gray-400">Not set</div>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 100,
      render: data => {
        return data ? moment(data).format('M/D/YYYY') : null;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 100,
      render: data => {
        return data ? moment(data).format('M/D/YYYY') : null;
      },
    },
    {
      title: 'Billing Date',
      dataIndex: 'billingDate',
      key: 'billingDate',
      width: 100,
      render: data => {
        return data ? moment(data).format('M/D/YYYY') : null;
      },
    },
    // ...(() => {
    //   if (hasFinanceRole) {
    //     return [
    //       {
    //         title: 'Accounting Code',
    //         dataIndex: 'accountingCode',
    //         key: 'accountingCode',
    //       },
    //       {
    //         title: 'Accounting Code Suffix',
    //         dataIndex: 'accountingCodeSuffix',
    //         key: 'accountingCodeSuffix',
    //       },
    //     ];
    //   }
    //   return [];
    // })(),
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      render: data => {
        // if (data.children) {
        //   console.log({ data });
        //   return null;
        // }
        if (!data.includeInAllProjects) {
          return (
            <ProjectDetailsDeliverableActions
              // isMilestone={data.milestoneName === data.deliverableName}
              milestoneNames={milestoneNames}
              deliverable={data}
              refetchQueries={refetchQueries}
            />
          );
        }
      },
    },
  ];

  return (
    <Query query={GET_DELIVERABLES_BY_PROJECT_ID} variables={{ projectId }}>
      {/* Line 195 receives an object w/ properties: loading, error, data. That data is then extracted below from the query into the deliverables const*/}
      {({ loading, error, data = [] }) => {
        if (error) throw new Error(error);

        const deliverables = data?.projectManagement?.project?.deliverables || [];
        const milestoneNames = deliverables.reduce((acc, cur) => {
          if (acc.includes(cur.milestoneName)) {
            return acc;
          }
          return [...acc, cur.milestoneName];
        }, []);
        const projectBudget = data?.projectManagement?.project?.budget || [];
        const totalDeliverableBudget = deliverables.reduce((acc, cur) => {
          return acc + cur.budget;
        }, 0);

        const columns = createColumns({
          milestoneNames,
          deliverables,
          refetchQueries: [
            {
              query: GET_DELIVERABLES_BY_PROJECT_ID,
              variables: {
                projectId,
              },
            },
          ],
        });

        let rowsObj = deliverables.reduce((acc, cur) => {
          if (acc[cur.milestoneName]) {
            return {
              ...acc,
              [cur.milestoneName]: [...acc[cur.milestoneName], cur],
            };
          } else {
            return {
              ...acc,
              [cur.milestoneName]: [cur],
            };
          }
        }, {});

        let rows = [];
        for (const [key, value] of Object.entries(rowsObj)) {
          const budget = value.reduce((acc, cur) => acc + cur.budget, 0);
          const totalCost = value.reduce((acc, cur) => acc + cur.totalCost, 0);
          const totalApprovedHours = value.reduce((acc, cur) => acc + cur.totalApprovedHours, 0);

          const milestoneDeliverable = deliverables.find(d => d.deliverableName === key) || {};
          const children = value
            .filter(c => c.deliverableName !== c.milestoneName)
            .map(c => {
              return {
                key: key + c.deliverableName,
                // dont show budget indicator if no budget was set
                budgetStatus:
                  c.budget > 0 ? (c.totalCost > c.budget ? 'Overbudget' : 'Onbudget') : null,
                milestoneBudget: milestoneDeliverable?.budget,
                ...c,
                calculatedBudgetPercent:
                  c.budgetPercent > 0 ? null : (c.budget / projectBudget) * 100,
              };
            });

          rows.push({
            ...milestoneDeliverable,
            key,
            milestoneName: key,
            budget,
            calculatedBudgetPercent:
              milestoneDeliverable.budgetPercent > 0 ? null : (budget / projectBudget) * 100,
            // dont show budget indicator if no budget was set
            budgetStatus: budget > 0 ? (totalCost > budget ? 'Overbudget' : 'Onbudget') : null,
            children: children.length > 0 ? children : null,
            projectId,
            totalCost,
            totalApprovedHours,
            deliverableName: null,
          });
        }

        return (
          <Card
            headerComponent={createHeaderComponent(deliverables)}
            actionComponent={
              <div className="flex gap-6 items-center">
                <div className="flex items-center gap-2">
                  <div>Blended Rate:</div>
                  <InputNumber value={blendedRate} onChange={value => setBlendedRate(value)} />
                </div>
                <Button disabled={lockEditing} onClick={() => setShowModal(true)}>
                  Add Deliverable
                </Button>
              </div>
            }
          >
            <Modal
              title="Add Deliverable"
              visible={showModal}
              destroyOnClose
              onOk={() => setShowModal(false)}
              onCancel={() => setShowModal(false)}
              footer={null}
            >
              <AddDeliverableForm
                projectId={projectId}
                refetchQueries={[
                  {
                    query: GET_DELIVERABLES_BY_PROJECT_ID,
                    variables: {
                      projectId,
                    },
                  },
                ]}
                onComplete={() => setShowModal(false)}
                milestoneNames={milestoneNames}
              />
            </Modal>
            <Table
            scroll={{ x: 1300 }}
              footer={() =>
                totalDeliverableBudget > projectBudget && (
                  <Alert
                    message={`Project budget is ${numeral(projectBudget).format(
                      '$0,0',
                    )} while total deliverable budget adds up to ${numeral(
                      totalDeliverableBudget,
                    ).format(
                      '$0,0.',
                    )}. Make sure that the total budget specified for deliverables above is equal to or less than project budget.`}
                    type="warning"
                  />
                )
              }
              columns={columns}
              pagination={false}
              loading={loading}
              dataSource={
                // make sure deliverables exist and arrived to browser
                deliverables && rows
                // deliverables
                //   .map(d => ({ ...d, key: d._id }))
                //   .filter(d => !d.includeInAllProjects)
                //   .sort((a, b) => {
                //     const milestone1 = a.milestoneName.toUpperCase();
                //     const milestone2 = b.milestoneName.toUpperCase();
                //     if (milestone1 < milestone2) {
                //       return -1;
                //     }
                //     if (milestone1 > milestone2) {
                //       return 1;
                //     }
                //     return 0;
                //   })
              }
            />
          </Card>
        );
      }}
    </Query>
  );
};

export default ProjectDetailsDeliverableList;

// const subTableColumns = [
//   {
//     title: 'Resource',
//     dataIndex: 'name',
//     key: 'name',
//   },
//   {
//     title: 'Email',
//     dataIndex: 'email',
//     key: 'email',
//   },
//   {
//     title: 'Approved Hours',
//     dataIndex: 'totalApprovedHours',
//     key: 'totalApprovedHours',
//   },
// ];
