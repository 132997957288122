import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Avatar, Tooltip, Tag, Input, Select } from 'antd';
import randomcolor from 'randomcolor';
import Trianglify from 'react-trianglify';
import classNames from 'classnames';
import Card from '../../common/components/Card';
import DataDisplay from '../../common/components/DataDisplay';
import Button from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import NewProjectForm from '../../admin/components/NewProjectForm';
import ProjectFinancialSummary from './ProjectFinancialSummary';

const { Search } = Input;
const { Option } = Select;

const ProjectList = ({
  projects = [],
  loading,
  history,
  title,
  onMore,
  onSearch,
  onClearSearch,
  onFilter,
  onSort,
  showFilters,
  showSearch,
  filter,
  sort,
  searchQuery,
}) => {
  const { loading: loadingClients, data } = useQuery(
    gql`
      query {
        projectManagement {
          clients {
            _id
            name
          }
        }
      }
    `,
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [user] = useGlobal('user');
  const isAdmin = user.groups.includes('admin');
  const isProjectManagement = user.groups.includes('project-management');
  const { location } = history;
  const { pathname } = location;
  const [sortType, setSortType] = useState(undefined);
  const [showSortOrder, setShowSortOrder] = useState(false);

  // const activeProjects = projects.filter(project => !project?.archived);
  // const archivedProjects = projects.filter(project => project?.archived);

  return (
    <div>
      <Card
        floating
        bodyBackground="none"
        padded={false}
        title={title}
        // loading={loading}
        // loadingText="Loading Projects"
        actionComponent={
          (isAdmin || isProjectManagement) && (
            <div>
              <Button onClick={() => setModalVisible(true)}>Add Project</Button>
              <Modal
                title="Create New Project"
                visible={modalVisible}
                footer={null}
                onCancel={() => setModalVisible(false)}
              >
                <NewProjectForm
                  onComplete={projectId => {
                    setModalVisible(false);
                    history.push(`/project-management/projects/${projectId}`);
                  }}
                  notifyUser={false}
                />
              </Modal>
            </div>
          )
        }
      >
        <DataDisplay
          filters={
            <>
              {showSearch && (
                <Search
                  allowClear
                  loading={searchQuery && loading}
                  style={{ width: 300 }}
                  placeholder="Search Projects"
                  onSearch={onSearch}
                  defaultValue={searchQuery}
                />
              )}
              {showFilters && (
                <div className="flex flex-col md:flex-row w-full justify-between">
                  <div className="flex gap-2 flex-col md:flex-row">
                    <Select
                      loading={filter.budgetUsed && loading}
                      value={filter.budgetUsed ? String(filter.budgetUsed * 100) : undefined}
                      allowClear
                      placeholder="Filter by Budget"
                      style={{ width: 175 }}
                      onChange={value => {
                        onFilter({ budgetUsed: Number(value) / 100 });
                      }}
                    >
                      <Option value="15">15% Budget Used</Option>
                      <Option value="25">25% Budget Used</Option>
                      <Option value="50">50% Budget Used</Option>
                      <Option value="75">75% Budget Used</Option>
                      <Option value="100">100% Budget Used</Option>
                    </Select>
                    <Select
                      allowClear
                      disabled={loadingClients}
                      value={filter.client}
                      loading={filter.client && loading}
                      placeholder="Filter by Client"
                      style={{ width: 175 }}
                      onChange={value => {
                        onFilter({ client: value });
                      }}
                    >
                      {data?.projectManagement?.clients
                        ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map(client => (
                          <Option key={client._id} value={client._id}>
                            {client.name}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="flex gap-2">
                    <Select
                      value={sort.sortBy}
                      allowClear
                      placeholder="Sort by"
                      style={{ width: 175 }}
                      onChange={value => {
                        onSort({ sortBy: value });
                        if (value === 'budgetUsed') {
                          setSortType('numeric');
                        }
                        if (value === 'name') {
                          setSortType('lexical');
                        }
                        if (value) {
                          setShowSortOrder(true);
                        } else {
                          setShowSortOrder(false);
                        }
                      }}
                    >
                      <Option value="budgetUsed">Budget Used</Option>
                      <Option value="name">Name</Option>
                    </Select>
                    {showSortOrder && (
                      <Select
                        value={sort.sortOrder ? String(sort.sortOrder) : '1'}
                        placeholder="Sort order"
                        style={{ width: 175 }}
                        onChange={value => {
                          onSort({ sortOrder: Number(value) });
                        }}
                      >
                        <Option value="-1">
                          {sortType === 'numeric' ? 'Highest First' : 'Z-A'}
                        </Option>
                        <Option value="1">{sortType === 'numeric' ? 'Lowest First' : 'A-Z'}</Option>
                      </Select>
                    )}
                  </div>
                </div>
              )}
            </>
          }
          data={projects?.sort((a, b) => (a?.archived ? 1 : -1)) || []}
          loading={loading}
          itemMinWidth="300px"
          itemMinHeight="200px"
          noDataMessage="No Projects Found"
          onGridRender={project => {
            const { resources } = project;
            const resourcesToShow = 5;
            return (
              <Link key={project._id} to={`${pathname}/${project._id}`}>
                <div
                  className={classNames(
                    'relative bg-white border border-solid border-gray-200 rounded-md text-gray-600 h-full overflow-hidden flex flex-col cursor-pointer',
                    {
                      'opacity-50 hover:opacity-100': project?.archived,
                    },
                  )}
                >
                  <div className="opacity-50">
                    <Trianglify
                      cell_size={300}
                      output="svg"
                      seed={project._id}
                      width={1150}
                      height={20}
                      x_colors={[
                        randomcolor({ seed: project._id, luminosity: 'light' }),
                        randomcolor({ seed: project._id, luminosity: 'dark' }),
                      ]}
                    />
                  </div>
                  <div className="p-4 relative">
                    <h2 className="mb-0 truncate">{project.name}</h2>
                    <div className="truncate">{project.client.name}</div>
                    {project?.archived && (
                      <Tag className="absolute top-0 right-0 m-2">Archived</Tag>
                    )}
                    <div className="flex justify-between">
                      <div className="text-gray-500">{project.projectCode}</div>
                      <div className="flex mb-2 items-center justify-end">
                        {resources
                          ?.filter((_, i) => i < resourcesToShow)
                          ?.map(r => {
                            return (
                              <Tooltip
                                key={r._id}
                                title={`${r.user?.firstName} ${r.user?.lastName}`}
                              >
                                <Avatar
                                  style={{
                                    backgroundColor: randomcolor({
                                      seed: r.user?.emails[0].address,
                                      luminosity: 'dark',
                                    }),
                                    verticalAlign: 'middle',
                                    marginLeft: '-15px',
                                    border: '1px solid white',
                                  }}
                                  src={r.user?.imageUrl}
                                >
                                  {`${r.user?.firstName[0]}${r.user?.lastName[0]}`}
                                </Avatar>
                              </Tooltip>
                            );
                          })}
                        {resources?.length > resourcesToShow && (
                          <Tooltip
                            title={`${
                              resources?.length - resourcesToShow
                            } more team members on this project`}
                          >
                            <div className="ml-1 text-gray-600">{`+${
                              resources?.length - resourcesToShow
                            }`}</div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <div className="relative overflow-hidden" style={{ height: '60px' }}>
                      <p>
                        {project.description?.length > 300
                          ? project.description.substring(0, 300 - 3) + '...'
                          : project.description}
                      </p>
                      <div
                        className="absolute bottom-0 left-0 right-0 bg-white h-10"
                        style={{
                          background:
                            'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,.2), rgba(255,255,255,1))',
                        }}
                      ></div>
                    </div>
                    {/* <div className="flex justify-start items-end mt-4 w-full">
                      <div className="h-10 pointer-events-none w-full">
                        <ResponsiveContainer width="100%" height="100%">
                           <AreaChart
                            width={200}
                            height={100}
                            data={project.activity}
                            margin={{
                              top: 0,
                              right: 0,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <Area
                              type="monotone"
                              dataKey="hours"
                              stroke={randomcolor({ seed: project._id, luminosity: 'dark' })}
                              fill={randomcolor({ seed: project._id, luminosity: 'light' })}
                            />
                          </AreaChart>
                          <LineChart height={50} data={project.activity}>
                            <Line type="monotone" dataKey="hours" dot={null} stroke="gray" />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-auto h-24 flex justify-center  items-center border-0 border-t border-solid border-gray-200">
                    <ProjectFinancialSummary
                      className="w-full"
                      project={project}
                      budgetUsed={project.budgetUsed}
                    />
                  </div>
                </div>
              </Link>
            );
          }}
        />
        {onMore && (
          <div className="w-full flex justify-center">
            <Button loading={loading} onClick={onMore}>
              Load More
            </Button>
          </div>
        )}
      </Card>

      {/* <Card
        floating
        bodyBackground="none"
        padded={false}
        title="Archived Projects"
        loadingText="Loading Projects"
      >
        <DataDisplay
          data={archivedProjects}
          viewType="list"
          loading={loading}
          itemMinHeight="0px"
          searchKeys={['name', 'projectCode', 'client.shortName']}
          noDataMessage="No Projects Found"
          onRowRender={project => {
            return (
              <Link to={`${pathname}/${project._id}`}>
                <div className="p-4">
                  <h2 className="text-gray-900">{project.name}</h2>
                  <p className="text-gray-600">{project.description}</p>
                </div>
              </Link>
            );
          }}
        />
      </Card> */}
    </div>
  );
};

export default ProjectList;
